import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Coding, Organization, Practitioner } from "fhir"
import { useFormikContext } from "formik"
import { Tooltip } from "primereact/tooltip"
import { FC, useEffect, useMemo, useRef } from "react"

import { DropdownField, InputField, PractitionerApi, ValueSetIds } from "commons"
import { useValueSet } from "value-set"

const AddressField: FC<Props> = ({ parentFieldName, showAutoComplete = true, disabled = false, context }) => {
  const { codes: states } = useValueSet({ valueSetId: ValueSetIds.CONTINENTAL_USA_STATES })
  const { address, setFieldValue } = useAddressContext(context)
  const googleInputRef = useRef<HTMLInputElement>(null)

  const parentFieldFullName = parentFieldName ? parentFieldName + "." : ""

  const isPoBoxAddress = useMemo(
    () => /\b(?:[Pp]\.?\s*[Oo]\.?|post\s+office)(\s+)?(?:[Bb]ox|[0-9]*)?\b/g.test(address?.line?.[0] ?? ""),
    [address?.line],
  )

  useEffect(() => {
    const options = {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    }

    try {
      const autoComplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
        googleInputRef.current as HTMLInputElement,
        options,
      )

      autoComplete.addListener("place_changed", () => {
        const { address_components } = autoComplete.getPlace()
        let route = ""
        let streetNumber = ""

        for (const component of address_components as google.maps.GeocoderAddressComponent[]) {
          const componentType = component.types[0]

          switch (componentType) {
            case "route": {
              route = component.long_name
              break
            }

            case "street_number": {
              streetNumber = component.long_name
              break
            }

            case "postal_code": {
              setFieldValue(`${parentFieldFullName}postalCode`, component.long_name)
              break
            }

            case "locality":
              setFieldValue(`${parentFieldFullName}city`, component.long_name)
              break

            case "administrative_area_level_1": {
              setFieldValue(`${parentFieldFullName}state`, component.short_name)
              break
            }
          }
        }

        setFieldValue(`${parentFieldFullName}line[0]`, `${streetNumber} ${route}`)
        setFieldValue(`${parentFieldFullName}line[1]`, "")
      })
    } catch {
      /* empty */
    }
  }, [parentFieldFullName, setFieldValue])

  return (
    <>
      <div className="flex col-span-2 space-y-2 flex-col">
        <InputField
          field={`${parentFieldFullName}line[0]`}
          label="Address Line 1"
          disabled={disabled}
          autocomplete="off"
          type="text"
          ref={showAutoComplete && "google" in window ? googleInputRef : null}
        >
          {isPoBoxAddress && (
            <>
              <Tooltip
                target=".tooltiped"
                event="hover"
                position="left"
                content="It appears that you are attempting to set a P.O. Box address. Please be aware that P.O. Boxes might not be suitable for receiving deliveries"
              />
              <span className="absolute top-10 left-96 z-10 cursor-pointer tooltiped">
                <FontAwesomeIcon icon={faInfoCircle} className="text-orange-300" />
              </span>
            </>
          )}
        </InputField>
        <InputField
          field={`${parentFieldFullName}line[1]`}
          label="Address Line 2"
          disabled={disabled}
          type="text"
          autocomplete="off"
        />
      </div>

      <div className="flex p-fluid grid-cols-3 gap-4 col-span-2">
        <InputField
          field={`${parentFieldFullName}city`}
          label="City"
          className="w-1/3 grow max-w-[40%]"
          disabled={disabled}
          autocomplete="off"
          type="text"
        />
        <DropdownField
          field={`${parentFieldFullName}state`}
          label="State"
          className="w-1/3 grow max-w-[40%]"
          options={states as Coding[]}
          optionLabel="display"
          disabled={disabled}
        />
        <InputField
          field={`${parentFieldFullName}postalCode`}
          label="ZIP Code"
          className="w-1/3 grow-0"
          disabled={disabled}
          autocomplete="off"
          type="text"
        />
      </div>
    </>
  )
}

const useAddressContext = (context: AddressContextType) => {
  const { setFieldValue, values } = useFormikContext()

  const address = useMemo(() => {
    switch (true) {
      case context === "Practitioner":
        return (values as Practitioner).address?.[0]
      case context === "Organization":
        return (values as Organization).address?.[0]
      case context === "PractitionerApi":
        return (values as PractitionerApi).practitioner.address?.[0]
      default:
        return undefined
    }
  }, [context, values])

  return { address, setFieldValue }
}

type AddressContextType = "Organization" | "PractitionerApi" | "Practitioner"

type Props = {
  context: AddressContextType
  parentFieldName?: string
  showAutoComplete?: boolean
  disabled?: boolean
}

export { AddressField }
