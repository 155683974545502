import { faCalendarDays, faPencil, faTrashCan } from "@fortawesome/pro-light-svg-icons"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Coding, Identifier } from "fhir"
import { FormikHelpers, FormikValues, useFormikContext } from "formik"
import { FC, useCallback, useEffect, useMemo } from "react"
import { Sidebar } from "primereact/sidebar"

import {
  AddFieldArrayItemButton,
  ConfirmDialog,
  FormContainer,
  FormField,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
  useReplaceFormContext,
  useScreenContext,
} from "commons"
import { identifierInitialValues } from "identifier"
import { formatStringDate } from "utils"
import { useFacilityProvidersOrgs } from "organizations"
import { IDENTIFIER_CODE } from "data"

import { IdentifierForm } from "./IdentifierForm"
import { getIdentifierInitialValue, getIdentifierValidationSchema, sanitize } from "./validations"
import { useCheckNPIIdentifier } from "../hooks"

const IdentifierContainer: FC<Props> = ({ field, label, types, avoidReplacement }) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>()
  const { organizationRefs: assigners } = useFacilityProvidersOrgs()
  const { isSmallScreen } = useScreenContext()
  const identifier: Identifier[] | undefined = useMemo(() => {
    const fieldSplit = field.split(".")
    const value =
      fieldSplit.length > 1
        ? values?.[fieldSplit[0] as keyof FormikValues][fieldSplit[1]]
        : values?.[fieldSplit[0] as keyof FormikValues]

    return Array.isArray(value) ? value : undefined
  }, [values])

  const { showSlide, initialValue, deleteIndex, editIndex, editWithIndex, add, reset, setDeleteIndex } = useCrudReducer(
    {
      defaultEntity: { ...identifierInitialValues } as Partial<Identifier>,
    },
  )

  const addIdentifier = useCallback(
    (data?: Identifier) => {
      const tempIdentifier = { ...data, system: data?.type?.coding?.[0]?.system ?? data?.system }
      const newIdentifier = sanitize(tempIdentifier)
      setFieldValue(field, [
        ...(editIndex !== undefined
          ? identifier?.toSpliced(editIndex, 1, newIdentifier) ?? []
          : [...(identifier ?? []), newIdentifier]),
      ])
      onReset()
    },
    [editIndex, identifier],
  )

  const handleSuccess = useCallback(
    ({
      npiExists,
      formikHelpers,
      identifier: data,
    }: {
      npiExists: boolean
      identifier?: Identifier
      formikHelpers: FormikHelpers<Partial<Identifier>>
    }) => {
      if (npiExists) {
        formikHelpers.setFieldError("value", "This NPI is in use")
        formikHelpers.setSubmitting(false)
        return
      }
      addIdentifier(data)
    },
    [],
  )

  const { checkNPIExists } = useCheckNPIIdentifier({
    onSuccess: handleSuccess,
  })

  const formReplaceContext = useReplaceFormContext<Identifier>()

  const onReset = () => {
    if (!avoidReplacement) formReplaceContext?.setShowReplacementContent?.(false)
    reset()
  }

  const onAdd = () => {
    add()
  }

  const onEdit = (value: Partial<Identifier>, index: number) => {
    editWithIndex(value, index)
  }

  const onSubmit = useCallback(
    (data: Identifier, formikHelpers: FormikHelpers<Partial<Identifier>>) => {
      if (data?.type?.coding?.[0].code === IDENTIFIER_CODE.NPI) {
        checkNPIExists({ identifier: data, formikHelpers })
        return
      }
      addIdentifier(data)
    },
    [addIdentifier],
  )
  const customSidebarTitlebar = (
    <span className="bg-white w-full">
      <h6 className="font-semibold"> {!initialValue.value ? "Add" : " Edit"} Identifier</h6>
    </span>
  )

  const replacementFormProps = useMemo(
    () => ({
      title: label,
      showForm: showSlide,
      useFormik: true,
      initialValue: getIdentifierInitialValue(initialValue),
      validationSchema: getIdentifierValidationSchema(!!types),
      saveLabel: initialValue.value ? "Save" : "Add",
      children: <IdentifierForm types={types} assigners={assigners} />,
      showCloseIcon: false,
      onCancel: onReset,
      onSubmit: onSubmit,
    }),
    [showSlide, initialValue, types, onSubmit],
  )

  useEffect(() => {
    if (!avoidReplacement) {
      if (showSlide) formReplaceContext?.setReplacementContent?.(replacementFormProps)
      else formReplaceContext?.setShowReplacementContent?.(false)
    }
  }, [replacementFormProps])

  const identifierModelBuilder = useCallback(
    (id: Identifier, index: number, setDeleteIndex: (index: number | undefined) => void): StackedListItemProps => {
      return {
        leftData: [
          {
            lineItems: [
              {
                name: "Value",
                value: id.value,
              },
            ],
          },
          {
            lineItems: [
              {
                name: "System",
                value: id.system,
              },
            ],
          },
          ...(id.period?.start || id.period?.end
            ? [
                {
                  lineItems: [
                    {
                      name: "Period",
                      value: `${formatStringDate(id.period?.start) ?? ""}${
                        id.period?.end ? "-" + formatStringDate(id.period?.end) : ""
                      }`,
                      icon: faCalendarDays,
                    },
                  ],
                },
              ]
            : []),
        ],
        menu: [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
            command: () => {
              if (identifier?.[index]) {
                onEdit(identifier[index], index)
              }
            },
            disabled: id.type?.coding?.[0].code === IDENTIFIER_CODE.NPI && id.use === "official",
          },
          {
            label: "Delete",
            icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
            command: () => setDeleteIndex(index),
            disabled: id.type?.coding?.[0].code === IDENTIFIER_CODE.NPI && id.use === "official",
          },
        ],
      }
    },
    [identifier],
  )

  const deleteItem = useCallback(
    (index: number) => {
      const updatedIdentifier = identifier?.toSpliced(index, 1)
      setFieldValue(field, updatedIdentifier)
    },
    [identifier],
  )

  return (
    <>
      <FormField field={field} label={label} className="w-full @container" showInvalidState>
        <AddFieldArrayItemButton className="px-2 py-4" onClick={onAdd} />
        {identifier?.length ? (
          <StackedListContainer
            itemsClassName="px-2 py-4"
            data={identifier}
            itemModelBuilder={(item, index) => identifierModelBuilder(item, index, setDeleteIndex)}
          />
        ) : (
          <div className="flex flex-col items-center justify-center py-5">
            <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
            <p className="text-slate-500 text-xs pt-1">No {label.toLowerCase()} added yet</p>
          </div>
        )}
      </FormField>
      <ConfirmDialog
        confirmText="Are you sure you want to remove this identifier?"
        actionName="Remove"
        visible={deleteIndex !== undefined}
        onConfirm={() => deleteItem(deleteIndex as number)}
        hideDialog={() => setDeleteIndex(undefined)}
      />

      {(!formReplaceContext || !!avoidReplacement) && (
        <Sidebar
          visible={showSlide}
          position={isSmallScreen ? "bottom" : "right"}
          className={isSmallScreen ? "h-[95%] rounded-t-xl" : "md:w-1/2 lg:w-1/3"}
          header={customSidebarTitlebar}
          onHide={onReset}
        >
          <div className="relative h-full w-full">
            <FormContainer
              initialValue={getIdentifierInitialValue(initialValue)}
              onSubmit={onSubmit}
              onCancel={onReset}
              enableReinitialize
              validationSchema={getIdentifierValidationSchema(!!types)}
            >
              <IdentifierForm types={types} assigners={assigners} />
            </FormContainer>
          </div>
        </Sidebar>
      )}
    </>
  )
}

type Props = {
  field: string
  label: string
  avoidReplacement?: boolean
  types?: Coding[]
}

export { IdentifierContainer }
