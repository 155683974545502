import { faBars, faPlus, faSearch } from "@fortawesome/pro-light-svg-icons"
import { faSitemap } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Organization } from "fhir"
import { Dialog } from "primereact/dialog"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { useCallback, useEffect, useId, useReducer, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
  Button,
  DataContainerForm,
  GroupedList,
  LoadingView,
  SearchWithFilters,
  SkeletonLoader,
  StackedListItem,
  useCrudReducer,
  useScreenContext,
} from "commons"
import { useLoginContext } from "security"

import {
  useActiveDeactiveOrganization,
  useCreateOrganization,
  useOrganizations,
  useOrganizationsPartOf,
  useUpdateOrganization,
} from "../hooks"
import { OrganizationApi, OrganizationListState } from "../types"
import { OrganizationFilters } from "./OrganizationFilters"
import { OrganizationForm } from "./OrganizationForm"
import { OrganizationOnboardModal } from "./OrganizationOnboardModal"
import { OrganizationPaymentModal } from "./OrganizationPaymentModal"
import { orgModelBuilder } from "./orgModelBuilder"
import { INITIAL_VALUES, initialValues, organizationValidationSchema, sanitize } from "./validations"

const OrganizationtList = () => {
  const { logedInPractitioner } = useLoginContext()
  const { organizationsRoots, isLoading: isLoadingRoots } = useOrganizationsPartOf(logedInPractitioner?.practitioner.id)
  const { searchText, active, search, filter, reset } = useReducerState()
  const [params, setParams] = useSearchParams()

  const { organizationGroups, isLoading, isFetchingNextPage, count, total, hasNextPage, fetchNextPage } =
    useOrganizations(searchText, active)

  const [showPaymentDialog, setShowPaymentDialog] = useState(false)
  const [showEnableDisableConfirm, setShowEnableDisableConfirm] = useState(false)
  const [showOnboardDialog, setShowOnboardDialog] = useState(false)
  const [org, setOrg] = useState<OrganizationApi | undefined>(undefined)
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const {
    initialValue,
    showSlide,
    isNew,
    add,
    edit,
    reset: closeForm,
  } = useCrudReducer({ defaultEntity: INITIAL_VALUES })

  const { createOrganization } = useCreateOrganization(closeForm)
  const { updateOrganization } = useUpdateOrganization(closeForm)

  const onSubmit = (organization: Organization) => {
    isNew ? createOrganization(sanitize(organization)) : updateOrganization(sanitize(organization))
  }

  const hidePaymentDialog = () => {
    setShowPaymentDialog(false)
    setOrg(undefined)
  }

  const hideEnableDisableConfirm = () => {
    setShowEnableDisableConfirm(false)
    setOrg(undefined)
  }
  const navigate = useNavigate()

  const viewAdministration = (org: Organization) => {
    navigate(`/organizations/${org.id}/administration`)
  }

  useEffect(() => {
    if (params.has("orgId")) {
      const orgId = params.get("orgId")
      if (orgId) {
        const organization = organizationGroups.flatMap((group) => {
          return group.items.filter((item) => item.organization?.id === orgId)
        })[0]?.organization
        edit(initialValues(organization))
      }
    } else {
      closeForm()
    }
  }, [params, organizationGroups])

  const closeOrgForm = () => {
    params.delete("orgId")
    setParams(params)
    closeForm()
  }
  const showEditForm = (organization?: Organization) => {
    params.append("orgId", organization?.id ?? "")
    setParams(params)
    edit(initialValues(organization))
  }
  const { isUpdating, updateOrganizationStatus } = useActiveDeactiveOrganization(hideEnableDisableConfirm)

  const handleShowStatusDialog = (org: OrganizationApi) => {
    setOrg(org)
    setShowEnableDisableConfirm(true)
  }

  const changeStatus = useCallback(() => {
    if (org?.organization) {
      updateOrganizationStatus(org.organization)
    }
  }, [org, updateOrganizationStatus])

  const handleShowPaymentDialog = (org: OrganizationApi) => {
    setOrg(org)
    setShowPaymentDialog(true)
  }

  const handleShowOnboardDialog = (org: OrganizationApi) => {
    setOrg(org)
    setShowOnboardDialog(true)
  }

  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)
  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <div className="pt-6 bg-white h-full flex flex-col">
      {!showSlide && (
        <div className="px-6 border-b shadow-sm">
          <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
            {isSmallScreen ? (
              <FontAwesomeIcon
                icon={faBars}
                size="lg"
                className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
                onClick={() => setSidebarCollapsed(false)}
              />
            ) : null}
            <div className={classNames("ml-2", { "flex flex-col items-end": isSmallScreen })}>
              <h6 className="font-medium text-lg">Organizations</h6>
              <p className="text-sm text-gray-500">
                Showing {count} organizations of {total} found
              </p>
            </div>
          </div>

          <div className="flex py-4 w-full justify-between">
            <div className="flex mr-2">
              <SearchWithFilters
                showOverlaypanel={overlayFilter}
                showSidebar={overlaySidebarFilter}
                setShowSidebar={setOverlaySidebarFilter}
                onTextFilter={search}
                isLoading={isLoading || isFetchingNextPage}
                filterNone={!active}
                formContent={
                  <OrganizationFilters
                    initialValues={{ active }}
                    onSearch={(filters) => {
                      filter(filters)
                      overlayFilter?.current?.hide()
                      setOverlaySidebarFilter(false)
                    }}
                    onClearFilters={() => {
                      reset()
                      overlayFilter?.current?.hide()
                      setOverlaySidebarFilter(false)
                    }}
                  />
                }
              />
            </div>

            <Button
              label={isSmallScreen ? "Add" : "Add organization"}
              icon={isSmallScreen ? faPlus : undefined}
              className="button-primary sm:text-sm"
              onClick={add}
            />
          </div>
        </div>
      )}
      {isLoading ? (
        loader()
      ) : (
        <DataContainerForm
          hasData={count > 0}
          showForm={showSlide}
          showAddButton={false}
          formTitle="Organization"
          iconDataNotFound={faSitemap}
          formInitialValue={initialValue}
          validationSchema={organizationValidationSchema}
          onSubmit={onSubmit}
          onCancel={closeOrgForm}
          form={
            isLoadingRoots ? (
              <LoadingView />
            ) : (
              <OrganizationForm organizationsRoots={organizationsRoots} isEditing={!isNew} />
            )
          }
          onButtonAddClick={() => {}}
          cancelButtonLabel="Close"
        >
          <div className="flex flex-col overflow-auto grow mb-5 ">
            <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useWindow={false} loader={loader()}>
              <GroupedList
                className="grow"
                groups={organizationGroups}
                renderItem={(organizationApi) => {
                  return (
                    <StackedListItem
                      itemPadding
                      modelData={orgModelBuilder(
                        organizationApi,
                        () => {
                          showEditForm(organizationApi.organization)
                        },
                        handleShowStatusDialog,
                        handleShowOnboardDialog,
                        handleShowPaymentDialog,
                        viewAdministration,
                      )}
                    />
                  )
                }}
                renderDivider={(name) => (
                  <div className="bg-gray-50 px-6 py-1 border-t border-b first:border-t-0">
                    <p className="text-sm text-gray-500 font-medium capitalize">{name}</p>
                  </div>
                )}
                renderEmptyState={() => (
                  <div className="flex flex-col items-center justify-center pt-10">
                    <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
                    <p className="text-slate-400 pt-3">No results found, please change filters and try again</p>
                  </div>
                )}
              />
            </InfiniteScroll>
            {showPaymentDialog && (
              <OrganizationPaymentModal onCancel={hidePaymentDialog} show={showPaymentDialog} org={org?.organization} />
            )}

            <Dialog
              header="Confirmation"
              closable={true}
              draggable={false}
              visible={showEnableDisableConfirm}
              className="w-full md:w-[70%] lg:w-[30%] m-2 "
              onHide={hideEnableDisableConfirm}
              footer={
                <div className="mt-2">
                  <Button
                    label="Cancel"
                    disabled={isUpdating}
                    className="button-default"
                    onClick={hideEnableDisableConfirm}
                  />
                  <Button label="Accept" className="button-primary" onClick={changeStatus} loading={isUpdating} />
                </div>
              }
            >
              <span>Are you sure you want to change the organization status?</span>
            </Dialog>

            {showOnboardDialog && (
              <OrganizationOnboardModal
                organization={org?.organization}
                show={showOnboardDialog}
                onCancel={() => {
                  setShowOnboardDialog(false)
                }}
              />
            )}
          </div>
        </DataContainerForm>
      )}
    </div>
  )
}

const initialState: OrganizationListState = {
  searchText: "",
  active: "",
}

const reducer = (
  state: OrganizationListState,
  { type, payload }: { type: "reset" | "search" | "filter"; payload: string | OrganizationListState },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return { ...state, ...(payload as OrganizationListState) }

    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, active }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset", payload: {} as OrganizationListState })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: OrganizationListState) => {
    dispatch({ type: "filter", payload: filters })
  }

  return { searchText, active, filter, search, reset }
}

export { OrganizationtList }
