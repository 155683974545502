import { Organization } from "fhir"
import * as Yup from "yup"

import { ContactPointSystem } from "data"
import { getRequiredAddressSchema, notPhoneRequiredTelecomSchema } from "utils"

const INITIAL_VALUES: Partial<Organization> = {
  active: false,
  name: "",
  resourceType: "Organization",
  type: [
    {
      coding: [
        {
          code: "healthcare-provider",
          display: "Healthcare Provider",
          system: "http://chartedhealth.com/fhir/organization-type",
        },
      ],
    },
  ],

  telecom: [
    { system: ContactPointSystem.email, use: "work", value: "" },
    { system: ContactPointSystem.phone, use: "work", value: "" },
  ],
  address: [
    {
      country: "US",
      line: ["", ""],
      city: "",
      state: "",
      postalCode: "",
      use: "billing",
    },
  ],
}

const initialValues = ({ telecom, partOf, ...rest }: Partial<Organization> = INITIAL_VALUES) => {
  const values: Organization = {
    ...INITIAL_VALUES,
    ...rest,
    partOf: partOf,
    telecom: [
      { ...INITIAL_VALUES.telecom?.[0], value: telecom?.[0]?.value ?? "" },
      { ...INITIAL_VALUES.telecom?.[1], value: telecom?.[1]?.value ?? "" },
    ],
  }

  return values
}

const sanitizeOrganizationAddress = ({ ...organization }: Partial<Organization>) => {
  const sanitizedAddress = organization.address?.filter(({ line }) => line?.[0] !== "")

  if (sanitizedAddress?.length) {
    return { ...organization, address: sanitizedAddress }
  }

  delete organization.address

  return organization
}

const sanitizeOrganizationIdentifiers = ({ ...organization }: Partial<Organization>) => {
  const sanitizedIdentifiers = organization.identifier?.filter(({ value }) => value !== "")

  if (sanitizedIdentifiers?.length) {
    return { ...organization, identifier: sanitizedIdentifiers }
  }

  delete organization.identifier

  return organization
}

const sanitize = ({ ...organization }: Organization) => {
  organization = sanitizeOrganizationAddress(organization)

  organization = sanitizeOrganizationIdentifiers(organization)

  return organization
}

const organizationValidationSchema = Yup.object().shape({
  name: Yup.string().required("The name is required"),
  telecom: Yup.array(notPhoneRequiredTelecomSchema),
  partOf: Yup.object().required("Part of is required"),
  address: Yup.array(getRequiredAddressSchema()),
})

export { initialValues, organizationValidationSchema, sanitize, INITIAL_VALUES }
