import { sub } from "date-fns"
import { humanNameAsString, Practitioner } from "fhir"
import * as Yup from "yup"

import { PractitionerApi } from "commons"
import { ContactPointSystem } from "data"
import { getNotRequiredAddressSchema, humanNameSchema, notPhoneRequiredTelecomSchema, telecomSchema } from "utils"

const INITIAL_VALUES: Partial<PractitionerApi> = {
  practitioner: {
    name: [{ use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }],
    active: true,
    gender: undefined,
    birthDate: undefined,
    telecom: [
      { system: ContactPointSystem.email, use: "home", value: "" },
      { system: ContactPointSystem.phone, use: "home", value: "" },
    ],
    address: [
      {
        country: "US",
        line: ["", ""],
        city: "",
        state: "",
        postalCode: "",
      },
    ],
  },
  roles: [],
}

const initialValues = (pract: Partial<PractitionerApi> = INITIAL_VALUES) => {
  const [name = { use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }] =
    pract.practitioner?.name ?? []

  return {
    ...pract,
    practitioner: {
      ...pract.practitioner,
      name: [name],
      address: [{ ...(INITIAL_VALUES.practitioner?.address?.[0] ?? {}), ...(pract.practitioner?.address?.[0] ?? {}) }],
    },
  } as PractitionerApi
}

const sanitizePractitionerAddress = ({ ...practitioner }: Partial<Practitioner>) => {
  const sanitizedAddress = practitioner.address?.filter(({ line }) => line?.[0] && line[0] !== "")

  if (sanitizedAddress?.length) {
    return { ...practitioner, address: sanitizedAddress }
  }

  delete practitioner.address

  return practitioner
}

const sanitizePractitionerTelecom = ({ ...practitioner }: Partial<Practitioner>) => {
  const sanitizedTelecoms = practitioner.telecom?.filter(({ value }) => value !== "")

  if (sanitizedTelecoms?.length) {
    return { ...practitioner, telecom: sanitizedTelecoms }
  }

  delete practitioner.telecom

  return practitioner
}

const sanitizePractitionerIdentifiers = ({ ...practitioner }: Partial<Practitioner>) => {
  const sanitizedIdentifiers = practitioner.identifier?.filter(({ value }) => value !== "")

  if (sanitizedIdentifiers?.length) {
    return { ...practitioner, identifier: sanitizedIdentifiers }
  }

  delete practitioner.identifier

  return practitioner
}

const sanitize = ({ ...pract }: PractitionerApi) => {
  if (pract.practitioner.name?.[0].given) {
    pract.practitioner.name[0].given = pract.practitioner.name[0].given.filter((value) => value && value !== "")

    pract.roles?.forEach((pr) => {
      const display = humanNameAsString(pract.practitioner.name?.[0])

      if (pr.practitioner && pr.practitioner?.display !== display) {
        pr.practitioner.display = display
      }
    })
  }

  if (pract.practitioner.name?.[0].suffix) {
    pract.practitioner.name[0].suffix = pract.practitioner.name[0].suffix.filter((value) => value && value !== "")
  }

  if (pract.practitioner.name?.[0].prefix) {
    pract.practitioner.name[0].prefix = pract.practitioner.name[0].prefix.filter((value) => value && value !== "")
  }

  if (!pract.practitioner?.birthDate) {
    delete pract.practitioner?.birthDate
  }

  pract.practitioner = sanitizePractitionerAddress(pract.practitioner)

  pract.practitioner = sanitizePractitionerTelecom(pract.practitioner)

  pract.practitioner = sanitizePractitionerIdentifiers(pract.practitioner)

  pract.roles?.forEach((pr) => {
    if (pr.id?.includes("new")) {
      delete pr.id
    }
  })

  return pract
}

const minDateTime = sub(new Date(), {
  minutes: 1,
})

const emailSchema = Yup.object().shape({
  email: Yup.string().email("Valid email is required").required("Email is required"),
})

const APIPractitionerValidationSchema = (parentFieldName?: string) =>
  Yup.object().shape({
    practitioner: Yup.object().shape({
      birthDate: Yup.date().nullable().max(minDateTime, "Practitioner birthdate should be before now"),
      name: Yup.array(humanNameSchema(parentFieldName)).min(1, "At least one name is required"),
      telecom: Yup.array(notPhoneRequiredTelecomSchema),
      address: Yup.array(getNotRequiredAddressSchema()),
    }),
    roles: Yup.array().min(1, "At least one practitioner role is required"),
  })

const LabSetupValidationSchema = Yup.object().shape({
  value: Yup.string().required("Value is required"),
  organization: Yup.object().required("Organization is required"),
})

export {
  APIPractitionerValidationSchema,
  emailSchema,
  humanNameSchema,
  initialValues,
  LabSetupValidationSchema,
  sanitize,
  telecomSchema,
}
