import { humanNameAsString, Patient } from "fhir"
import InfiniteScroll from "react-infinite-scroller"
import { faBars, faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useId, useReducer, useRef, useState } from "react"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { Sidebar } from "primereact/sidebar"

import {
  GroupedList,
  LoadingView,
  SkeletonLoader,
  StackedListItem,
  useScreenContext,
  SearchWithFilters,
  ConfirmDialog,
} from "commons"

import { useActiveDeactivePatient, useInvitePatient, usePatients } from "../hooks"
import { FilterProps, PatientInfo } from "../types"
import { PatientEmptyState } from "./PatientEmptyState"
import { PatientFilters } from "./PatientFilters"
import { patientModelBuilder } from "./patientModelBuilder"
import { PatientInvite } from "./PatientInvite"
import { PatientDetailView } from "./PatientDetailView"

const PatientList: React.FC = () => {
  const { searchText, email, gender, search, filter, reset } = useReducerState()
  const [showOverlayForm, setShowOverlayForm] = useState(false)
  const [patientInfo, setPatientInfo] = useState<PatientInfo | undefined>()
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showActiveDeactiveDialog, setShowActiveDeactiveDialog] = useState(false)
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()

  const onCancel = () => {
    setPatientInfo(undefined)
    setShowInviteModal(false)
  }

  const customSidebarTitlebar = (
    <span className="bg-white w-full flex items-center space-x-2">
      <h6 className="font-semibold">Patient details</h6>
    </span>
  )
  const closeForm = () => {
    setPatientInfo(undefined)
    setShowOverlayForm(false)
  }
  const showPatientDetails = (patientInfo: PatientInfo) => {
    setPatientInfo(patientInfo)
    setShowOverlayForm(true)
  }

  const handleInviteModal = (patientInfo: PatientInfo) => {
    setPatientInfo(patientInfo)
    setShowInviteModal(true)
  }
  const handleActiveDeactivePatient = (patientInfo: PatientInfo) => {
    setPatientInfo(patientInfo)
    setShowActiveDeactiveDialog(true)
  }
  const hideActiveDeactiveDialog = () => {
    setShowActiveDeactiveDialog(false)
    setPatientInfo(undefined)
  }

  const { invitePatient, isInviting } = useInvitePatient(
    patientInfo?.patient.id ?? "",
    humanNameAsString(patientInfo?.patient.name?.[0]),
    onCancel,
  )
  const { updatePatientStatus, isUpdating: isChangingStatus } = useActiveDeactivePatient()

  const changeStatus = (pat: PatientInfo) => {
    const { patient } = pat
    if (patient) updatePatientStatus(patient)
  }
  const { patientsGroups, count, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, total } = usePatients(
    searchText,
    email,
    gender,
  )

  const overlayFilter = useRef<OverlayPanel>(null)
  const [overlaySidebarFilter, setOverlaySidebarFilter] = useState(false)
  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <div className="pt-6 bg-white h-full flex flex-col">
      <div className="px-6 border-b shadow-sm">
        <div className={classNames(" flex", { "justify-between": isSmallScreen })}>
          {isSmallScreen ? (
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              className="cursor-pointer pt-0.5 hover:text-primary-hover mt-1"
              onClick={() => setSidebarCollapsed(false)}
            />
          ) : null}
          <div className={classNames("ml-2", { "flex flex-col items-end": isSmallScreen })}>
            <h6 className="font-medium text-lg">Patients</h6>
            <p className="text-sm text-gray-500">
              Showing {count} patients of {total} found
            </p>
          </div>
        </div>

        <div className="flex py-4 w-full ">
          <div className={classNames("flex mr-2  ", { "w-full": isSmallScreen })}>
            <SearchWithFilters
              showOverlaypanel={overlayFilter}
              showSidebar={overlaySidebarFilter}
              setShowSidebar={setOverlaySidebarFilter}
              onTextFilter={search}
              isLoading={isLoading || isFetchingNextPage}
              filterNone={!email && !gender}
              formContent={
                <PatientFilters
                  initialValues={{ gender, email }}
                  onSearch={(filters) => {
                    filter(filters)
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                  onClearFilters={() => {
                    reset()
                    overlayFilter?.current?.hide()
                    setOverlaySidebarFilter(false)
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        loader()
      ) : !patientsGroups?.length ? (
        <PatientEmptyState />
      ) : (
        <div className="flex flex-col overflow-auto grow mb-5 ">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useWindow={false} loader={loader()}>
            <GroupedList
              className="grow"
              groups={patientsGroups}
              renderItem={(patientInfo) => {
                return (
                  <StackedListItem
                    itemPadding
                    modelData={patientModelBuilder(
                      patientInfo,
                      handleInviteModal,
                      showPatientDetails,
                      handleActiveDeactivePatient,
                    )}
                  />
                )
              }}
              renderDivider={(name) => (
                <div className="bg-gray-50 px-6 py-1 border-t border-b first:border-t-0">
                  <p className="text-sm text-gray-500 font-medium capitalize">{name}</p>
                </div>
              )}
              renderEmptyState={() => (
                <div className="flex flex-col items-center justify-center pt-10">
                  <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
                  <p className="text-slate-400 pt-3">No results found, please change filters and try again</p>
                </div>
              )}
            />
          </InfiniteScroll>
          {showInviteModal && (
            <PatientInvite
              pat={patientInfo as PatientInfo}
              showInviteDialog={showInviteModal}
              hideInviteDialog={onCancel}
              isInviting={isInviting}
              invitePatient={invitePatient}
            />
          )}
          {
            <ConfirmDialog
              visible={showActiveDeactiveDialog || isChangingStatus}
              onConfirm={() => changeStatus(patientInfo as PatientInfo)}
              hideDialog={hideActiveDeactiveDialog}
              isLoading={isChangingStatus}
              confirmText={`Do you want to ${
                patientInfo?.patient?.active === false ? "activate" : "deactivate"
              } this patient?`}
            />
          }
          <Sidebar
            visible={showOverlayForm}
            position={isSmallScreen ? "bottom" : "right"}
            style={isSmallScreen ? { height: "95%" } : { minWidth: "30%" }}
            header={customSidebarTitlebar}
            onHide={closeForm}
          >
            {isLoading ? (
              <LoadingView />
            ) : (
              <div className="relative h-full pt-5 w-full">
                {patientInfo && <PatientDetailView {...{ ...(patientInfo ?? {}) }}></PatientDetailView>}
              </div>
            )}
          </Sidebar>
        </div>
      )}
    </div>
  )
}

const initialState = {
  searchText: "",
  email: "",
  gender: "",
} as FilterProps

const reducer = (
  state: FilterProps,
  { type, payload }: { type: "reset" | "search" | "filter"; payload: string | FilterProps | Patient },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return { ...state, ...(payload as FilterProps) }

    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, email, gender }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset", payload: {} })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: FilterProps) => {
    dispatch({ type: "filter", payload: filters })
  }

  return { searchText, email, gender, filter, search, reset }
}

export { PatientList }
